import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Blogs from "../components/Blogs"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const blog = ({ data }) => {
  const {
    allStrapiBlogs: { nodes: blogs },
  } = data

  return (
    <Layout>
      <Seo Sitetitle="Blog" />
      <ImageBanner
        heading="Blog"
        img="https://www.theminimalists.com/wp-content/uploads/2019/01/how-to-start-a-blog-in-2020-1.jpg"
        para="Discover the latest blogs from golden wave!"
      />
      <Container>
        {blogs.length === 0 && <h2 className="my-2">No Blogs,stay tuned!</h2>}
        {blogs.map(blog => (
          <Blogs blog={blog} key={blog.id} />
        ))}
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    allStrapiBlogs {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        id
        slug
        title
        short_description
        author
        date
      }
    }
  }
`

export default blog
