import React from "react"
import Header from "./Header"

const ImageBanner = ({ heading, para, img }) => {
  return (
    <section className="banner">
      <div className="banner-container">
        <div className="nav-container">
          <Header />
        </div>
        <img src={img} className="hero-img" alt="hero-img" />
        <div className="details-container">
          <div className="container">
            <h1>{heading}</h1>
            <p>{para}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageBanner
