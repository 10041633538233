import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"
import moment from "moment"

const Blogs = ({ blog }) => {
  const imgPath = getImage(blog.image?.localFile)

  return (
    <>
      <section className="blog d-none d-lg-block">
        <Link to={`/blog/${blog.slug}`}>
          <h4 className="my-3">
            <strong>{blog.title}</strong>
          </h4>
          <Row className="align-items-center">
            <Col lg={6} className="blog-img">
              <GatsbyImage image={imgPath} alt={blog.title} />
            </Col>
            <Col lg={6} className="blog-det">
              <p className="my-3">{blog.short_description}</p>
              <div className="blog-footer my-4 d-flex justify-content-between">
                <p>{blog.author}</p>
                <p>{moment(blog.date).format("DD MMMM, YYYY")}</p>
              </div>
            </Col>
          </Row>
        </Link>
      </section>
      <section className="m-blog my-3 blog-d-block d-lg-none">
        <Link to={`/blog/${blog.slug}`}>
          <Row className="align-items-center justify-content-center">
            <Col xs={6}>
              <div className="small-img my-2">
                <GatsbyImage image={imgPath} alt={blog.title} />
              </div>
            </Col>
            <Col xs={6}>
              <div className="m-blog-det">
                <h4>
                  <strong>{blog.title}</strong>
                </h4>
                <div className="blog-footer">
                  <p>{moment(blog.date).format("DD MMMM, YYYY")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Link>
      </section>
    </>
  )
}

export default Blogs
